.client-intro-step-container {
    display: flex;
    //border: 2px solid red;
    min-height: 60vh;
    align-items: center;
    justify-content: center;
}

.client-intro-step-container p {
    text-align: center;
    font-size: 1.2rem;
}