@import '../../../global.scss';

.document-container {
    min-height: 60vh;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem 1rem;
        border-bottom: 1px solid $primary-blue;
        font-size: 1.75rem;
        width: 90%;
    }

    &-body {
        width: 90%;
    }

    .document-card {
        margin-top: 1rem;
        padding: 1rem;
        box-shadow: 4px 2px 6px 2px #ccc;
        width: 100%;

        &-header {
            display: flex;
            justify-content: space-between;
        }

        .document-card-id-type {
            color: $primary-blue;
            font-size: 1.35rem;
        }

        .document-card-label {
            font-weight: bold;
        }
    }
}