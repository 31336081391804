.create-advisor-form-container {
    width: 100%;
    display: flex;
    justify-content: center;

    form {

        .create-advisor-form-control {
            display: flex;
            align-items: center;
            margin: 1rem;

            label {
                min-width: 150px;
                font-weight: bold;
            }

            input, select {
                min-width: 300px;
            }
        }

        .create-advisor-form-control-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem;
        }

        .create-advisor-form-error{
            color: red;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}