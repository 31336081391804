$primary-color: #0076c6;
$mobile-max-width: 480px;
$tablet-max-width: 768px;
$small-screen-width: 1024px;
$desktop-screen-width: 1200px;

$electric-indigo: #7700ff;
$periwinkle: #B8B8FF;
$gunmetal: #1E3231;

$ghost-white: #F8F7FF;
$old-gold: #E2C044;
$tiffany-blue: #A6ECE0;

$electric-indigo-with-opacity: rgba(119, 0, 255, 0.5);


// New Colors 
$primary-gold: #FEC010;
$primary-blue: #2C7EF4;
$primary-blue-hover: #84b7ff7c;
$primary-grey: #4A5764;
$secondary-green: #008037;
$secondary-purple: #802164;
$secondary-grey: #EDEDED;

// Screen Sizes
$mobile-min-width: 320px;
$tablet-min-width: 768px;
$small-screen-min-width: 1024px;
$desktop-screen-min-width: 1200px;

