@import '../../../global.scss';

.participant-details-container {
    background: white;
    box-shadow: 2px 4px 2px 2px #ccc;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    //min-height: 50vh;

    @media(min-width: $small-screen-min-width){
        flex-direction: row;
    }

    .participant-details-nav-bar {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //border: 2px solid red;
        //border: 2px solid red;

        @media(min-width: $small-screen-min-width){
            flex-direction: column;
            width: 30%;
        }

        .participant-details-navbar-item {
            margin: 0.5rem 0;
            width: 100%;

            @media(min-width: $tablet-min-width){
                width: 50%;
            } 

            @media(min-width: $small-screen-min-width){
                width: 100%;
            }    

            button {
                background: none; 
                border: none;
                padding: 0.25rem 4rem;
                min-width: 100%;
                font-size: 1.25rem;
                border-radius: 0px 15px;
                transition: all 200ms
            }

            &.active, &:hover {
                button {
                    //background: linear-gradient(90deg, #4A5764 80.53%, #008037 97.87%);
                    background-color: $primary-grey;
                    color: white;
                }
            }
        }
    }


    .participant-details-section{
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media(min-width: $small-screen-min-width){
            width: 60%;
        }
        
        .participant-details-section-header {
            font-size: 2rem;
            margin-bottom: 0.25rem;
            
            &-underline{
                width: 100%;
                height: 2px;
                background-color: $primary-blue;
                margin-bottom: 1rem;
                box-shadow: 1px 1px 0px 0px #ccc;
            }
        }

        .participant-regulatory-detail-section-header {
            width: 100%;
            font-size: 1.2em;
            margin-bottom: 1rem;
            background-color: $primary-blue-hover;
            padding: 0.5rem;
            font-weight: bold;
        }

        .participant-details {
            width: 100%;
            margin: 1% 0;
            display: flex;
            
            .full-label-container {
                min-width: 45%;
                display: flex;
                align-items: center;

                label {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-right: 1rem;
                }

                i {
                    font-size: 0.9rem;
                }
            }

            .full-input-container{
                width: 100%;
                display: flex;
                align-items: center;

                .dollar-sign {
                    margin-right: 0.5rem;
                }

                .input-container{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                }
            }

            .input-container {
                width: 40%;
            }
            
        
            input {
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.5rem;
                transition: all 100ms;
                
                &:focus {
                    outline: none;
                    
                    + .underline {
                        width: 100%;
                        
                    }
                }

                &:disabled {
                    background: #e9e9e9;
                    cursor: not-allowed
                }

                
            }

            .underline {
                width: 0%;
                height: 2px;
                background-color: $primary-blue;
                transition: all 150ms;
                
            }

            select {
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.5rem;
                min-width: 70%;
                transition: all 100ms;
                
                &:focus {
                    outline: none;
                    + .underline {
                        width: 100%;
                    }
                }

                &:disabled {
                    background: #e9e9e9;
                    cursor: not-allowed
                }
            }

            .radio-button-container {
                width: 50%;


                input {
                    width: 5%;
                }

                label {
                    font-weight: normal;
                }
            }
        }
        
        .insider-details-container {
            width: 100%;

            .add-insider-detainer-btn-cont {
                margin: 1rem 0;

                button {
                    background-color: $primary-blue;
                    color: white;
                    border: none;
                    padding: 0.25rem 1rem;
                    border-radius: 0.5rem;
                    transition: all 140ms;

                    &:hover {
                        background-color: $primary-blue-hover;
                        color: black;
                    }
                }
            }

            .insider-table-container {

                table {
                    width: 100%;
                    border-collapse: collapse;
                    border: 1px solid #ccc;
                    margin: 1rem 0;
        
                    th, td {
                        border: 1px solid #ccc;
                        padding: 0.5rem;
                        width: 45%;
        
                        &:nth-child(3){
                            width: 10%;
                            text-align: center;
                        }
        
                        button {
                            background-color: rgb(246, 89, 89);
                            border: 1px solid red;
                            transition: all 200ms;
                            color: white;
        
                            &:hover {
                                background-color: rgb(246, 113, 89);
                            }
                        }
                    }
        
                    

                    th {
                        background-color: $primary-blue;
                        color: white;
                    }
        
                    tr:nth-child(even) {
                        background-color: #f2f2f2;
                    }
        
                    td {
                        input {
                            padding: 0.3rem;
                            border-radius: 0.5rem;
                            border: 1px solid #ccc;
                        }
                    }
        
                    tr{
                        &:hover {
                            background-color: #ddd;
                        }
                    }
                }
            }
        }

        .table-container {
            width: 100%;

            .table-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-header-text {
                    font-size: 1.25rem;
                    font-weight: bold;
                }

                .table-add-btn {
                    background-color: $primary-blue;
                    color: white;
                    border: none;
                    padding: 0.25rem 1rem;
                    border-radius: 0.5rem;
                    transition: all 200ms;

                    &:hover {
                        background-color: $periwinkle;
                    }
                }
            }

            .table-body {
                width: 100%;;
            }

            .asset-table {
                width: 100%;
                border-collapse: collapse;
                border: 1px solid #ccc;
                margin: 1rem 0;
    
                th, td {
                    border: 1px solid #ccc;
                    padding: 0.5rem;
                    width: 45%;
    
                    &:nth-child(3){
                        width: 10%;
                        text-align: center;
                    }
    
                    button {
                        background-color: rgb(246, 89, 89);
                        border: 1px solid red;
                        transition: all 200ms;
                        color: white;
    
                        &:hover {
                            background-color: rgb(246, 113, 89);
                        }
                    }
                }
    
                
    
                th {
                    background-color: $primary-blue;
                    color: white;
                }
    
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
    
                td {
                    input {
                        padding: 0.3rem;
                        border-radius: 0.5rem;
                        border: 1px solid #ccc;
                    }
                }
    
                tr{
                    &:hover {
                        background-color: #ddd;
                    }
                }
            }
        }
    }

    .actions-container {
        width: 100%;
        
        @media(min-width: $small-screen-min-width){
            width: 15%;
        }
        //border: 2px solid red;
        .actions-container-btns {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button{
                margin: 1rem;
                width: 100%;
                padding: 0.25rem 1rem; 
                display: flex;
                align-items: center;
                justify-content: center;
                
                div {
                    margin-left: 0.25rem;
                }
            }
        }
    }
}