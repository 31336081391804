@import "../../../global.scss";

.page-container{
    display: flex;
    width: 100%;
}

.households-content-container {
    overflow-y: auto;
    //max-height: 80vh;
}


.households-container {
    padding: 1rem;
    width: 90%;
}

.client-progress-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 40%;
}


.container-header {
    font-size: 3.5rem;
    margin-left: 0.75rem;
}

.households-main-container, .main-page-list-container {
    background-color: white;
    width: 95%;
    margin: 1rem;
    min-height: 90vh;
    padding: 1rem;
    box-shadow: 3px 3px 4px 4px #ccc;

    .households-header, .main-page-list-header {
        display: flex;
        justify-content: space-between;

        .main-page-list-information-back-btn-container {
            
            button {
                background: none;
                border: none;
            }
        }

    
        .households-header-text, .main-page-list-header-text {
            font-family: 'Roboto Condensed Bold';
            color: $primary-blue;
            font-size: 3rem;
        }
    
        .households-subheader-text, .main-page-list-subheader-text {
            margin-top: -0.5rem;
            font-family: 'Cabin Bold';
            color: $primary-grey;
            font-size: 1rem;
        }

        .household-header-search, .main-page-list-search-container {
            
            input {
                border-radius: 1rem;
                border: 1px solid #ccc;
                padding: 0.5rem 1rem;
            }
        }

        .add-new-btn {
            padding: 0.25rem 2rem;
            border: none;
            border-radius: 1rem;
            margin-top: 0.5rem;
            background-color: $primary-blue;
            color: white;
            transition: all 200ms;

            &:hover {
                background-color: $secondary-green;
            }
        }
    }

    .hr {
        border: 0.5px solid #ccc;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 0.75rem;
    }

    .household-list-container, .main-list-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50vh;
        }

        .household-row, .main-list-row-item {
            background:  rgba(74, 87, 100, 0.05);
            padding: 1rem;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 4px 4px 4px 3px #ccc;
            transition: background 200ms;

            @media (min-width: $small-screen-min-width) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            &:hover {
                cursor: pointer;
                background:  rgba(74, 87, 100, 0.15);
            }

            .household-row-information, .main-list-row-information {
                display: flex;
                width: 15%;
                flex-direction: column;
                align-items: center;

                @media (min-width: $small-screen-min-width) {
                    align-items: flex-start;
                }

                .household-row-id, .id-info {
                    font-family: 'Roboto Condensed Bold';
                    text-align: center;
                    color: $primary-grey;
                }

                .household-row-name, .primary-info {
                    font-family: 'Cabin Bold';                    
                    text-align: center;
                    font-size: 1.8rem;
                    color: $primary-blue;

                    @media (min-width: $small-screen-min-width) {
                        font-size: 1.3rem;
                    }
                }

                

                .household-row-status {
                    margin-top: 0.25rem;
                    padding: 0.1rem;
                    background: rgba(254, 192, 16, 0.20);
                    width: 30%;
                    text-align: center;
                    border-radius: 1rem;
                }
            }

            .household-row-actions {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;

                @media (min-width: $small-screen-min-width) {
                    margin-top: 0;
                }
                
                .household-view-actions-container {
                    
                    button {
                        margin: 0.5rem;
                    }

                    .household-row-action-btn {    
                        border: none;
                        padding: 0.25rem 1rem;
                        border-radius: 1rem;
                        transition: all 200ms;
                        display: flex;
                        align-items: center;
                    }
                    
                    .view-participants-btn {
                        background-color: $secondary-green;
                        color: $secondary-grey;
                    
                        &:hover {
                            background-color: $primary-blue;

                            i {
                                opacity: 1;
                            }

                            .icon-holder {
                                transform: translateX(0rem);
                            }
                        }
                        
                        .icon-holder {
                            transform: translateX(-0.75rem);
                            transition: transform 200ms;
                        }

                        i {
                            position: relative;    
                            opacity: 0;
                            margin-left: 0.5rem;
                            font-size: 1.25rem;
                            transition: all 200ms;
                        }
                    
                    }               
                }
            }
        }
    }
}



