@import "../../../global.scss";


.household-container {
    padding: 1rem;
    border-bottom: 1px solid black;
    //max-height: 60vh;
}

.household-name {
    font-size: 1.2rem;
    min-width: 300px;
    font-weight: bold;
}

.household-header {
    display: flex;
    align-items: center;
}

.household-clients{
    //padding: 0.25rem;
}

.household-client {
    border: 2px solid black;
    padding: 0.5rem;
    transition: all 300ms;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        background-color: #e6b10267;
    }
}

.documention-completion {
    display: flex;

    p {
        min-width: 200px;
    }
}

.household-container  {
    table{
        width: 100%;

        @media (min-width: $tablet-max-width){
            width: 100%;
        }

        @media (min-width: $small-screen-width){
            width: 90%;
        }
    }
}

.add-client-btn {
    background-color: transparent;
    border: none;
}

.add-client-btn i {
    color: rgb(2, 163, 23);
    font-size: 1.5rem;
}

.expand-btn {
    background: transparent;
    border: none;
    margin-right: 0.1rem;
}

.edit-house-btn {
    background-color: transparent;
    border: none;
    

    i {
        font-size: 1.5rem;
        color: rgb(2, 48, 255);
    }
}

.households-container-header {
    display: flex;
    align-items: center;

    h1 {
        min-width: 370px;
    }

    button {
        background-color: transparent;
        border: none;
    }

    i {
        font-size: 2rem;
        color: #e6b002;
        transition: color 300ms;

        &:hover {
            color: black;
        }

    }
}