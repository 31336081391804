select {
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #ccc;

    &:focus {
        outline: none;

        + .underline {
            width: 100%;
            
        }
    }

    &:disabled {
        background-color: #f5f5f5;
        color: black;
    }
}