@import '../../../mixins.scss';
@import '../../../global.scss';

.main-document-container {
    margin: 1rem;
    width: 95%;
    min-height: 88vh;
    @include panel;

    .container-header {

        h1 {
            color: $primary-blue;
            font-family: "Roboto Condensed Bold";
        }

        h3 {
            font-size: 1.2rem;
        }
    }
    .container-divider {
        margin: 1rem 0;
        border-bottom: 1px solid #ccc;
    }

    .container-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-header{
            text-align: center;
            margin-top: 1rem;
            h1 {
                font-size: 1.3rem;
            }
        };

        .document-form{
            margin-top: 1rem;
            width: 100%;
            //border: 2px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .document-form-input-container{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.75rem;

                label {
                    min-width: 15%;
                    font-weight: 600;
                }

                .input-container {
                    min-width: 20%;

                    input, select {
                        width: 100%;
                    }
                }
            }
        }
    }
}