@import '../../../global.scss';
@import '../../../mixins.scss';

.create-ips-full-container {
    margin: 1rem;
    width: 95%;
    min-height: 88vh;
    @include panel;

    .create-ips-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;

        .ips-container-header {
            font-size: 2.5rem;
            font-weight: 600;
            color: $primary-blue;
        }
        .ips-container-subheader {
            font-size: 1.2rem;
            margin-top: 0.25rem;   
        }
    }

    .action-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem;
    }
}

.ips-section-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ips-section-header {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        color: $primary-blue;
    }   
}


.ips-section-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;

    &-header {
        margin: 1rem 0;
        text-align: center;
        font-size: 1.2rem;
    }

    .input-section {
        margin: 1rem;
        border-bottom: 1px solid #ccc;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        

        .input-container {
            margin-bottom: 1rem;
        }
    }

    .radio-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        input {
            margin: 0 1rem;    
        }
    }

    textarea {
        width: 95%;
        height: 20vh;
        padding: 1rem;
        transition: all 200ms;

        &:focus {
            border: 1.5px solid $primary-blue;
            outline: none;
        }
    }

    input {
        padding: 0.25rem;
        font-size: 1.1rem;
        margin-right: 2rem;
        margin-bottom: 0rem;
        width: 100%;
    }

    input[type="radio"] {
        margin-right: 0;
        text-align: center;
    }

    .ips-objective-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        //border-bottom: 1px solid black;
    }   
}


.account-fee-description-form {
    margin: 1rem;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border: 2px solid #ccc;
    

    .form-header {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .input-control {
        width: 100%;
        display: flex;
        margin: 0.5rem;
        justify-content: center;

        label {
            min-width: 12rem;
            font-weight: bold;
            min-width: 30%;
        }
    
        .input-container {
            min-width: 40%;
            //display: flex;
            //align-items: center;
        }
    }

    

    // input {
    //     border-radius: 10rem;
    //     padding: 0.5rem 1rem;
    //     border: 1px solid #ccc;
    //     box-shadow: 2px 2px 2px 2px #ccc;
    // }
}

.account-fee-descriptions {
    box-shadow: 2px 2px 2px 2px #ccc;
    width: 50%;
    padding: 1rem;
    margin-bottom: 2rem;

    .account-fee-description {
        border-bottom: 1px solid #ccc;
        //border: 2px solid red;

        .account-fee-description-header-container {
            display: flex;
            justify-content: space-between;
        }

        .account-fee-description-header {
            font-size:1.1rem;
            font-weight: bold;
        }
    }
}

.review-details-section {
    width: 60%;
    margin-bottom: 1rem;;
    border-bottom: 1px solid #ccc;

    &.section-row {
        display: flex;
        justify-content: space-between;
    }

    .label {
        font-weight: bold;
        margin-right: 1rem;
    }

    .value {
        margin-bottom: 0.5rem;
    }

    .account-descriptions-review{
        display: flex;
        .account-description-section {
            margin: 0.5rem 1rem;
        }
    }
}

.ips-section-body-container-header {
    font-size: 1.5rem;
}

.ips-objective-inputs-section {
    display: flex;

    .ips-objective-input-section{
        margin: 1rem;
        display: flex;
    }
} 

.ips-account-fee-table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem;

    table {
        width: 65%;
        border-collapse: collapse;
        //border: 1px solid #ccc;
        box-shadow: 2px 2px 5px 2px #d6d6d6;
        //border-radius: 1rem;

        th, td {
            border: 1px solid #ccc;
            padding: 0.5rem;
            text-align: center;
        }

        th {
            background-color: $primary-blue;
            color: white;
            
        }

    }
}

