.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
}

.smile {
    font-size: 3rem;
}

.header {
    color: black;
    font-size: 3rem;
}

.subheader{
    font-size: 1.5rem;
}