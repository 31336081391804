@import '../../../global.scss';

.participant-workflow-container{
    background-color: white;
    min-height: 50vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .workflow-container-header {
        text-align: center;
        font-size: 1.8rem;
    }

    .workflow-container-header-underline {
        width: 90%;
        height: 0.1rem;
        background-color: #1E90FF;
        margin: 1rem 0;
    }

    .participant-workflows {
        width: 90%;

        .workflow-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            //align-items: flex-start;

            &-header {
                font-size: 1.2rem;
                font-weight: bold;
                margin-bottom: 1rem;
                
            }

            .workflow-item {
                width: 100%;
                //min-height: 10vh;
                box-shadow: 2px 2px 2px 2px #ccc;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 0.5rem;

                &-header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-text {
                        font-size: 1.4rem;
                        font-weight: bold;
                        display: flex;
                        align-items: center;

                        i{
                            font-size: 1.8rem;
                            margin-left: 0.5rem;

                            &.not-complete {
                                color: $primary-gold;
                            }

                            &.complete {
                                color: green;
                            }
                        }
                    }

                    .action-items-container {
                        display: flex;
                    }
                }

                .workflow-items-status-container {
                   margin-top: 1rem;
                   display: flex;
                   flex-direction: column;
                   width: 100%;
                   flex-wrap: wrap;

                   @media (min-width: $small-screen-min-width){
                        flex-direction: row;
                   }

                    .workflow-item-status-container{
                        width: 95%;
                        margin: 0.5rem;
                        min-height: 8vh;

                        @media (min-width: $small-screen-min-width){
                            width: 15%;
                        }


                        .status-btn {
                            border: none;
                            padding: 0.5rem 1rem;
                            border-radius: 0.5rem;
                            margin-right: 1rem;
                            display: flex;
                            width: 100%;
                            transition: all 200ms;
                            height: 100%;
                            display: flex;
                            align-items: center;
    

                            .bi {
                                margin-right: 0.5rem;
                                font-size: 1.2rem;
                            }
                            
                            .lds-ring {
                                margin-left: 0.5rem;
                            }
                        }

                        .no-cursor {
                            cursor: auto;
                        }

                        .not-complete-status-btn {
                            background-color: $primary-gold;
                            color: black;
                            
                            &:hover {
                                background-color: gold;
                            }
                        }

                        .complete-status-btn {
                            background-color: $primary-blue;
                            color: white;

                            &:hover {
                                background-color: $primary-blue-hover;
                                color: black;
                            }
                        }

                        .previous-step-not-completed-status-btn {
                            cursor: not-allowed;
                        }
                    }
                }

                .divider{
                    width: 100%;
                    height: 0.1rem;
                    background-color: #ccc;
                    margin: 1rem 0;
                }

                .document-container {

                    .document-container-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 1rem;

                        &-text {
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
                    }

                    .embedded-document {
                        width: 100%;
                        height: 60vh;
                    }
                }

                .risk-parameters-container {
                    margin-top: 1rem;
                    &-header {
                        font-size: 1.2rem;
                        text-align: center;
                    }

                    &-subheader {
                        font-size: 1.1rem;
                        text-align: center;
                        margin-top: 1rem;
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                    }

                    .risk-parameter-input-container{
                        margin: 0.5rem;
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;



                        label{
                            min-width: 25%;
                            font-weight: bold;
                        }
                        input {
                            //width: 10%;
                            border: none;
                            padding: 0.5rem;
                            border-bottom: 1px solid #ccc;

                            &:focus {
                                outline: none;
                            }
                        }
                    }

                    .risk-parameter-button-container {
                        button {
                            padding: 0.5rem 1rem;
                            border: none;
                            background-color: $primary-blue;
                            color: white;
                            border-radius: 0.5rem;
                            transition: all 200ms;

                            &:hover {
                                background-color: $primary-blue-hover;
                                color: black;
                            }
                        }
                    }
                }
            }

        }
    }
}