@import '../../../global.scss';
@import '../../../mixins.scss';
.main-create-bank-container {
    margin: 1rem;
    width: 95%;
    min-height: 88vh;
    @include panel;

    .container-header {
        h1 {
            color: $primary-blue;
            font-family: "Roboto Condensed Bold";
        }

        h3 {
            font-size: 1.2rem;
        }
    }
    .container-divider {
        margin: 1rem 0;
        border-bottom: 1px solid #ccc;
    }

    .container-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        //border: 2px solid red;
    }

    .container-form{
        margin-top: 1rem;
        width: 100%;
        //border: 2px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .account-input-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.75rem;
            
            label {
                min-width: 20%;
                font-weight: 600;
                font-size: 1rem;
            }

            .input-container {
                min-width: 70%;

                input:not([type=radio]), select {
                    width: 100%;
                }
            }
        }
    }
}