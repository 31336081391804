@import '../../../mixins.scss';
@import '../../../global.scss';
.kyc-container {
    margin: 1rem;
    width: 95%;
    min-height: 88vh;
    @include panel;

    &-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        //align-items: center;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;

        h1 {
            font-size: 2.5rem;
            font-weight: 600;
            color: $primary-blue;
        }

        h3 {
            font-size: 1.2rem;
            margin-top: 0.25rem;
        }
    }

    .kyc-container-header {
        button {
            background-color: transparent;
            border: none;
            text-align: left;
        }
    }
}

.kyc-section-container {
    margin-top: 1rem;

    &-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;

        h1 {
            font-size: 2rem;
            font-weight: 600;
            color: $primary-blue;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .kyc-label-input-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            //border: 2px solid red;
            margin: 0.4rem;

            .kyc-label-container {
                width: 40%;

                label {
                    font-size: 1rem;
                    font-weight: 600;
                }

                a {
                    margin-left: 1rem;
                }

                @media(min-width: $small-screen-min-width){
                    width: 20%;
                }
            }

            .kyc-input-container {
                width: 30%;
                margin-left: 2rem;
                //display: flex;
                //align-items: center;

                select, input:not([type=radio]) {
                    width: 100%;
                }

                select {
                    padding: 0.5rem;
                    border: none;
                    border-bottom: 1px solid #ccc;

                    &:focus {
                        outline: none;

                        + .underline {
                            width: 100%;
                            
                        }
                    }
                }

                &.flex {
                    display: flex;
                    align-items: center;
                    width: 30%;
                }
            }
        }
        
        .kyc-section-divider-header {
            font-size: 1.5rem;
            margin: 0.5rem;
        }

        .insider-details-container {
            width: 60%;
            justify-content: center;

            .add-insider-detainer-btn-cont {
                margin: 1rem 0;

                button {
                    background-color: $primary-blue;
                    color: white;
                    border: none;
                    padding: 0.25rem 1rem;
                    border-radius: 0.5rem;
                    transition: all 140ms;

                    &:hover {
                        background-color: $primary-blue-hover;
                        color: black;
                    }
                }
            }
            .insider-table-container {

                table {
                    width: 100%;
                    border-collapse: collapse;
                    border: 1px solid #ccc;
                    margin: 1rem 0;
        
                    th, td {
                        border: 1px solid #ccc;
                        padding: 0.5rem;
                        width: 45%;
        
                        &:nth-child(3){
                            width: 10%;
                            text-align: center;
                        }
        
                        button {
                            background-color: rgb(246, 89, 89);
                            border: 1px solid red;
                            transition: all 200ms;
                            color: white;
        
                            &:hover {
                                background-color: rgb(246, 113, 89);
                            }
                        }
                    }
        
                    

                    th {
                        background-color: $primary-blue;
                        color: white;
                    }
        
                    tr:nth-child(even) {
                        background-color: #f2f2f2;
                    }
        
                    td {
                        input {
                            padding: 0.3rem;
                            border-radius: 0.5rem;
                            border: 1px solid #ccc;
                        }
                    }
        
                    tr{
                        &:hover {
                            background-color: #ddd;
                        }
                    }
                }
            }
        }

        .create-new-btn-cont{
            margin-top: 1rem;
            button {
                background-color: $primary-blue;
                padding: 0.5rem 1rem;
                border-radius: 2rem;
                color: white;
                border: none;
                transition: all 140ms;

                &:hover{
                    background-color: $primary-blue-hover;
                    color: black;
                }
            }
        }

        .regulatory-divider {
            width: 100%;
            height: 1px;
            background-color: #ccc;
            margin: 1rem 0;
        }

        .cancel-btn-cont, .submit-btn-cont {
            display: flex;
            justify-content: center;
        }

        .submit-btn-cont {
            button {
                background-color: $primary-blue;
                padding: 0.5rem 1.5rem;
                color: white;
                border: none;
                border-radius: 1rem;
                transition: all 140ms;
                &:hover {
                    background-color: $primary-blue-hover;
                    color: black;
                }
            }
        }
    }
}