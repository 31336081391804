.client-completion-container {
    padding: 1rem;
    text-align: center;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    overflow:hidden
}

.client-completion-header {
    font-size: 1.5rem;
    margin: 1rem;
}
  
.checkmark-container {
    display: flex;
    align-items: center; 
    
    i {
        font-size: 3rem;
        margin-left: 1rem;
    }
}

  