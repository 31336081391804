@import '../../../global.scss';

.accounts-container {
    min-height: 60vh;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 1rem;
        border-bottom: 1px solid $primary-blue;
        font-size: 1.75rem;
        width: 90%;
        flex-direction: column;
    }

    &-body {
        display: flex;
        flex-direction: column;
        width: 90%;

        .account-container {
            box-shadow: 2px 2px 2px 2px #ccc;
            width: 100%;
            margin-top: 1rem;
            padding: 1rem;
            display: flex;
            justify-content: space-between;

            .account-attribute {
                font-size: 1.2rem;

                .label {
                    font-weight: bold;
                }
            }

            .account-actions {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 20%;

                .action-btn-container {
                    display: flex;

                    button {
                        margin: 1rem;
                    }
                }
            }
        }
    }
}