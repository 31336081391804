.risk-special-notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    margin-top: 2rem;
    transition: opacity 200ms;
    min-height: 50vh;
    min-width: 80%;
}

.risk-special-notes-header {
    font-size: 2rem;
}

.risk-special-notes-subheader {
    font-size: 1rem;
}

.risk-special-notes-text-area {
    margin-top: 2rem;
    width: 60%;
    height: 10rem;
}