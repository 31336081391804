@import '../../../global.scss';

.form-progress-bar {
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: flex-start;
    color: black;
    flex-wrap: wrap;

    @media(min-width: $small-screen-width){
        padding: 0.5rem 2rem;
    }

    .form-progress-bar-step{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 2%;

        .form-progress-bar-step-number{
            font-size: 1.3rem;
            font-weight: bold;
            color: white;
            background-color: #ccc;
            padding: 0.5rem;
            border-radius: 12rem;
            width: 4rem;
            min-height: 4rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 1.8rem;
            }
        
            &.complete {
                background-color: $primary-blue;
            }
        }

        .form-progress-bar-step-label {
            margin-top: 0.5rem;
            font-size: 1rem;
            text-align: center;
        }

        &.active {

            .form-progress-bar-step-number{
                background-color: $primary-gold;
            }

            .form-progress-bar-step-label {
                font-weight: bold;
            }
        }
    }
    

    .form-progress-bar-outerline {
        position: relative;
        width: 8%;
        height: 0.5rem;
        background: rgb(126, 126, 126);
        margin: 0 1.1rem;
        margin-top: 2rem;
        border-radius: 10rem;
        .form-progress-bar-innerline{
            width: 0%;
            height: 100%;
            background: #00A3FF;
            transition: width 0.5s ease-in-out;
            border-radius: 10rem;
        }
    }
}

.form-progress-container{
    padding: 1rem;

    .form-progress-btns-container {
        display: flex;
        justify-content: space-between;
        padding: 1rem 10rem;

        .form-progress-btn-container{

            button {
                padding: 0.25rem 1.25rem;
                border: none;
                background-color: $primary-blue;
                color: white;
                font-size: 1.2rem;
                transition: 0.3s all ease-in-out;
                border-radius: 0.5rem;

                &:hover {
                    background-color: $primary-blue-hover;
                    color: black;
                }

                &:disabled {
                    background-color: rgb(190, 190, 190) ;

                    &:hover {
                        cursor: not-allowed;
                        color: white;
                    }
                }
            }
        }
    }
}