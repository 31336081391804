@import '../../../global.scss';

.sia-navbar-list {
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sia-navbar-item {
        margin: 1rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 125ms ease-in-out;
        padding: 0.75rem;
        border-radius: 1.5rem;
        font-size: 1.3rem;
        font-weight: bold;

        p {
            min-width: 60%;
            display: none;

            @media (min-width: $small-screen-min-width) {
                display: block;
            }
        }
        i {
            font-size: 1.5rem;
        }

         
        &:hover, &.active {
            background: $primary-blue-hover;
            cursor: pointer;
        }
    }
}