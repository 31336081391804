@import '../../../global.scss';
@import '../../../mixins.scss';
.main-container{
    @include panel;
    padding: 1rem;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    width: 90%;
}

.main-container-header {
    color: $primary-blue;
    margin: 1rem;
    font-size: 3.25rem;
    text-align: center;
}







