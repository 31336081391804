@import "../../../global.scss";
@import "../../../mixins.scss";
.main-create-trade-container{
    margin: 1rem;
    width: 95%;
    @include panel;
    min-height: 95vh;

    &-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        //align-items: center;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;

        h1 {
            font-size: 2.5rem;
            font-weight: 600;
            color: $primary-blue;
        }

        h3 {
            font-size: 1.2rem;
            margin-top: 0.25rem;
        }
    }

    .main-create-trade-container-body {
        width: 100%;
        .account-section-details-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .account-section-details-header {
                text-align: center;
                margin-bottom: 2rem;
                
                h1 {
                    font-size: 2rem;
                    color: $primary-blue;
                    font-weight: 600;
                }
            }

            .account-section-details-body {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .account-section-detail {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0.5rem;
                
                    .label-container {
                        min-width: 30%;
                        font-weight: bold;

                        i {
                            margin-left: 1rem;
                        }
                    }

                    .input-container {
                        min-width: 60%;

                        input:not([type=radio]), select {
                            width: 100%;
                            }
                    }
                }

                .pac-swp-table-container {
                    width: 90%;

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        //border: 1px solid #ccc;
                        box-shadow: 2px 2px 5px 2px #d6d6d6;
                        //border-radius: 1rem;

                        th, td {
                            border: 1px solid #ccc;
                            padding: 0.5rem;
                            text-align: center;
                        }

                        th {
                            background-color: $primary-blue;
                            color: white;
                            
                        }

                    }
                }
            }

            .account-beneficiary-designation-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                .label-container {
                    min-width: 30%;
                    font-weight: bold;
                }

                .designations-container {
                    min-width: 60%;
                    padding-left: 1rem;
                }
            }

            .account-holder-container {
                width: 40%;
                
                .account-holder-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 1rem;
                    border: 1px solid #ccc;
                    box-shadow: 2px 2px 5px 2px #d6d6d6;
                    padding: 1rem 2rem;
                    border-radius: 1rem;

                    p {
                        font-size: 1.2rem;
                        font-weight: 600;
                        //color: $primary-blue;
                        
                        //text-align: center;
                    }
                }
            }

            .participant-table-container {
                width: 100%;
                display: flex   ;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                .table-header {
                    font-size: 1.2rem; 
                    font-weight: bold;
                    margin: 0.5rem;
                }

                table {
                    width: 50%;
                    border-collapse: collapse;
                    //border: 1px solid #ccc;
                    box-shadow: 2px 2px 5px 2px #d6d6d6;
                    //border-radius: 1rem;

                    th, td {
                        border: 1px solid #ccc;
                        padding: 0.5rem;
                        text-align: center;
                    }

                    th {
                        background-color: $primary-blue;
                        color: white;
                        
                    }
                }
            }

            .add-account-holder-btn-container {
                margin-top: 2rem;
            }

            .add-form{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem;
                box-shadow: 2px 2px 2px 2px #d6d6d6;

                .form-header {
                    font-size: 1.2rem;
                }

                @media (min-width: $small-screen-min-width){
                    width: 60%;
                }

                .account-section-detail {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0.5rem;
                    
                
                    .label-container {
                        min-width: 30%;
                        font-weight: bold;
    
                        i {
                            margin-left: 1rem;
                        }
                    }
    
                    .input-container {
                        min-width: 60%;
    
                        input:not([type=radio]), select {
                            width: 100%;
                        }
                    }
                }

                .table-container {
                    width: 100%;
                    margin: 1rem;

                    .table-header{
                        display: flex;
                        justify-content: center;
                        font-size: 1.25rem;
                        font-weight: bold;
                    }

                    table {
                        margin-top: 2rem;
                        width: 100%;
                        border-collapse: collapse;
                        //border: 1px solid #ccc;
                        box-shadow: 2px 2px 5px 2px #d6d6d6;
                        //border-radius: 1rem;
    
                        th, td {
                            border: 1px solid #ccc;
                            padding: 0.5rem;
                            text-align: center;
                        }
    
                        th {
                            background-color: $primary-blue;
                            color: white;
                            
                        }
                    }
                }
                
                .form-action-button-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    
                    button {
                        margin: 0.5rem;
                    }
                }
            }
            
            .action-section-btn-container {
                display: flex;
                margin-top: 2rem;

                button {
                    margin: 0.5rem;
                }
            }

            .transfer-detail-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                &-header {
                    text-align: center;
                    width: 100%;
                    font-size: 1.25rem;
                }

                &-body {
                    margin: 0.5rem;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &-card {
                        border: 1px solid #ccc;
                        box-shadow: 2px 2px 5px 2px #d6d6d6;
                        width: 80%;
                        margin: 0.25rem;

                        &-header {
                            background: $primary-blue;
                            padding: 0.5rem;
                            font-size: 1.2rem;
                            color: white;
                        }

                        &-body {
                            border: #ccc;
                            padding: 0.5rem;
                            display: flex;
                            justify-content: space-between;

                            .label {
                                font-weight: bold;
                            }

                            p {
                                margin: 0.25rem;
                            }
                        }
                    
                        .transfer-detail-container-actions {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            button {
                                margin: 0.5rem;
                            }
                        }
                    }

                }
            }

            .account-section-body {
                margin-top: 1rem;
                width: 100%;
                display: flex;
                justify-content: center;
                
                .new-participant-form, .existing-participant-form {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &-header {
                        font-size: 1.25rem;
                        margin-bottom: 1rem;
                        font-style: italic;
                    };

                    .new-participant-label-input-container, .existing-participant-label-input-container {
                        margin: 0.5rem 0;
                        width: 90%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .label-container{
                            min-width: 18%;
                            font-weight: 600;
                        }

                        .input-container{
                            min-width: 30%;
                            
                            input:not([type=radio]), select {
                                width: 100%;
                            }
                        }
                    }

                    .action-btn-container {
                        button {
                            margin: 0.5rem;
                        }
                    }
                }
            }
            
        }

        .action-items-container {
            display: flex;
            align-items: center;

            button {
                margin: 1rem;
            }
        }
    }
}