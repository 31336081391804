.risk-wizard {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    //min-height: 85vh;
    margin-bottom: 10rem;
    margin: auto;
}

.main-risk-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-button {
    margin: 1rem;
    background: transparent;
    border: none;
    
    i {
        font-size: 2.5rem;
        color: black;
        transition: 0.5s;
            
        &:hover {
            color: grey;
        }
    }
}
    
.risk-score-container{
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 1rem;

    p {
        font-size: 1.25rem;
        font-weight: bold;
    }
}

.risk-wizard-progress-btn-container{
    text-align: center;
    position: relative;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
}