@import '../../../global.scss';

.create-new-client-form-container{
    padding: 0.5rem;
    width: 100%;
    
}

.create-new-client-header {
    font-size: 1.5rem;
    text-align: center;
}

.create-new-client-form-control {
    margin-top: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-new-client-form-control  {

    label {
        min-width: 25%;
        font-weight: bold;
    }

    .input-container {
        min-width: 20%;
        margin-left: 1rem;

        input {
            width: 100%;
        }
    }
}



.create-new-client-btn-control {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.participant-role-create {
    
    &-header{
        font-size: 1.5rem;
        text-align: center;
    }

    .participant-role-container {
        display: flex;
        align-items: center;
        margin: 0.25rem;

        label {
            font-size: 1.2rem;
        }
    }
}

.form-body-inputs {
    display: flex;
    justify-content: space-evenly;
    //align-items: center;
}

.new-participant-basic-info-header {
    font-size: 1.5rem;
}

.role-icon {
    width: 40px;
    height: 40px;
    margin: 0rem 0.5rem;

    &:hover {
        cursor: pointer;
    }
}

.create-new-client-btn-control {
    button {
        padding: 0.4rem 2rem;
        background: $primary-blue;
        color: white;
        border: none;
        border-radius: 0.5rem;
        transition: all 200ms;

        &:hover {
            cursor: pointer;
            background: $primary-blue-hover;
            color: black;
        }
    }
}