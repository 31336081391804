
@import '../../../global.scss';

input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    transition: all 100ms;
    
    &:focus {
        outline: none;
        
        + .underline {
            width: 100%;
            
        }
    }

    &:disabled {
        background: #e9e9e9;
        cursor: not-allowed
    }

    
}

.underline {
    width: 0%;
    height: 2px;
    background-color: $primary-blue;
    transition: all 150ms;
    
}