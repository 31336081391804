@import '../../../global.scss';

.admin-container{
    width: 95%;
    margin: 1rem;
    min-height: 95vh;
    background-color: white;
}

.admin-header {
    padding: 1rem;
    width: 100%;
    margin: auto;
    
    h1 {
        font-family: 'Roboto Condensed Bold';
        color: $primary-blue;
        font-size: 3rem;
    }
}

.hr {
    border: 1px solid #ccc;
    margin: auto;
    width: 98%;
}

.users-table-container {
    padding: 1rem;
    width: 100%;
    margin: auto;
    

    .users-table-header {
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;
        margin: 1rem auto;

        .users-table-header-text {
            display: flex;
            align-items: center;
            h1 {
                font-size: 1.5rem;
                font-weight: bold;
                margin-right: 1rem;
            }
        }

        input {
            padding: 0.5rem 1rem;
            border: 1px solid #ccc;
            border-radius: 0.5rem;
        }
    }

    .users-table {
        width: 90%;
        margin: auto;
        border: 1px solid #ddd;
        font-size: 1rem;  
        height: 100%;      
        overflow-y: auto;
        display: block;
        height: 500px;
        overflow-y: auto;

        th, td {
            text-align: left;
            padding: 1.1rem;
        }

        th {
            background-color: $primary-blue;
            color: white;
            border: 1px solid white;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #ddd;
        }
    }
}

.create-new-user-form-container {
    margin: auto;
    width: 100%;
    padding: 1rem;
    &-header {
        width: 90%;
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 1.5rem;
        }

        button {
            margin-left: 1.4rem;
        }
    }

    form {
        width: 90%;

        .create-new-user-form-control {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem;

            label {
                min-width: 200px;
                font-weight: bold;
                font-size: 1.1rem;
            }

            .create-new-user-roles-container {
                width: 300px;
            }

            .input-container {

                input, select{
                    width: 300px;
                }
            }
        }

        .form-button-container{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem;
        }
    }
}

